import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <ul>
          <li>1526 N Irwin Green Bay 54302</li>
          <li>Thurs-Sat 9am-4pm</li>
          <li>EvaMissionInc@gmail.com</li>
        </ul>
      </footer>
    </>
  );
};

export default Footer;
